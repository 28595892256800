<template>
    <v-expand-transition>
        <router-view />
    </v-expand-transition>
</template>
<script>
import refreshData from '@/mixins/mixins'

export default {
    name: "MissionDetail",
    components: {},
    mixins:[refreshData],
    data() {
        return {
            apiUrl: "/mission/get?id=" + this.$route.params.mission_id,
            mutation: "missions/setMissionData"
        };
    },
    created() {
        this.refreshData();
    },
    // destroyed() {
    //     this.$store.dispatch('base/SET_SUB_MENU', [])
    //     this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
    // },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
        next();
    },
    methods: {},
    computed: {
        mission: function() {
            return this.$store.state.missions.mission;
        },
    },
    watch: {
        $route(to, from) {
            this.apiUrl = "/mission/get?id=" + to.params.mission_id,
            this.refreshData();
        },
        mission(newVal) {
            // Ajout des subActions
            this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
            if(newVal.actions && newVal.actions.get) {
                newVal.actions.get.forEach(element => element.item ? element.item = JSON.parse(JSON.stringify(this.mission)) : '');
                newVal.actions.get = JSON.parse(JSON.stringify(newVal.actions.get).replaceAll('{{id}}', this.mission.id));
                let actions = this._.cloneDeep(newVal.actions.get)
                actions.forEach(action => {
                    if(action.rules){
                        action.rules.forEach(rule => {
                            if(Array.isArray(rule.value)){
                                if(!rule.value.includes(newVal[rule.field]))
                                    action.hide = true
                            } else {
                                if(rule.value != newVal[rule.field])
                                    action.hide = true
                            }
                        });
                    }
                    if(action.hasOwnProperty('menus')){
                        let hiding = 0;
                        action.menus[0].forEach(subaction => {
                            if(subaction.rules){
                                subaction.rules.forEach(rule => {
                                    if(Array.isArray(rule.value)){
                                        if(!rule.value.includes(newVal[rule.field])){
                                            subaction.hide = true
                                        }
                                    } else {
                                        if(rule.value != newVal[rule.field]){
                                            subaction.hide = true
                                        }
                                    }
                                });
                                if(subaction.hide)
                                    hiding++
                            }
                        })
                        if(hiding == action.menus[0].length){
                            action.hide = true;
                        }
                    }
                });
                this.$store.dispatch("base/SET_HEADER_ACTIONS", actions);
            }
        }
    }
};
</script>

<style lang="scss">
</style>